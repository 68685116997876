<template>
    <div v-if="visible" class="rule-wrap">
        <div class="content">
            <i class="close-btn" @click="closeModal"></i>

            <div class="title">活动规则</div>
            <div class="text-wrap">
                <p>
                    1、本次活动无报名人数限制，填写报名登记表后即为报名成功。好友助力活动开始时间为2023年10月8日12:00。
                </p>
                <p>
                    2、报名参加者需邀请好友通过宠物筹每人助力1分钱，满50人助力即可获得领取宠物粮资格，由于宠物粮数量有限，先到先得。
                </p>
                <p>
                    3、本次活动共有卫仕全价冻干猫粮20袋、卫仕全价冻干狗粮9袋，多种口味随机发货。
                </p>
                <p>
                    4、当有20人完成猫粮助力活动，猫粮助力活动自动结束；当有9人完成狗粮助力活动，狗粮助力活动自动结束。活动结束后不可再助力。猫粮助力活动与狗粮助力活动互不影响。
                </p>
                <p>
                    5、本次活动所有助力金额将捐给北京微爱基金会用于流浪动物救助。
                </p>
            </div>
            <div class="btn" @click="closeModal2">知道了</div>
        </div>
    </div>
</template>

<script>
import saUtils from 'sensors-data-utils';
export default {
    name: 'modalKeYong',
    props: ['visible'],
    data() {
        return {
            text: '',
        };
    },

    methods: {
        closeModal() {
            this.$emit('update:visible', false);
            saUtils.click('button_close_applyrulespop','h5_pop_jsb_onepoint_apply_rules')
        },
        closeModal2() {
            this.$emit('update:visible', false);
            saUtils.click('button_know_rulespop','h5_pop_jsb_onepoint_apply_rules')
        },
    },

    mounted() {
        // alert(2)
    },
};
</script>

<style scoped lang="less">
.rule-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 300;
    .content {
        // margin: 0 34px;
        // height: 410px;
        // padding-bottom: 40px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 8px;
        width: 86%;
        position: absolute;
        &:before {
            content: '';
            display: table;
        }
        .title {
            font-size: 18px;
            color: #333;
            text-align: center;
            margin: 20px auto 20px;
            line-height: 1em;
            font-weight: 500;
        }
        .text-wrap {
            // height: 320px;
            padding: 0 15px;
            overflow-y: scroll;
            overflow-x: hidden;
            text-align: left;
            color: #666;
            font-size: 14px;
            line-height: 1.4em;
        }
        .btn {
            padding: 13px 30px;
            // width: 255px;
            // background-image: linear-gradient(to right, #6DC53A, #e91919);
            background-color: #6dc838;

            border-radius: 25px;
            line-height: 1em;
            font-size: 18px;
            color: #fff;
            min-width: 130px;
            font-weight: 600;
            margin: 24px 16px;
        }
    }

    .small-lion {
        position: absolute;
        left: 12px;
        top: -15px;
        width: 49px;
        height: 47px;
    }

    .close-btn {
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 8px;
        right: 8px;
        z-index: 10;
        // border: 1px solid #999;
        // border-radius: 15px;

        &:after,
        &:before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 16px;
            background: #c3c3c3;
            position: absolute;
            left: 14px;
            top: 7px;
            transform: rotate(45deg);
        }
        &:before {
            transform: rotate(-45deg);
        }
    }
}
</style>
