<template>
    <div class="main" v-if="dataCom">
        <top :projInfo="projInfo"></top>
        <div class="huizong" v-if="projInfo.receive_num > 0">
            <div class="left">
                已有<span>{{ projInfo.receive_num }}</span
                >个小{{ typeName }}赢得{{ typeName }}粮
            </div>
            <span class="right" @click="toPaiHang">查看排行榜>></span>
        </div>
        <projInfo :projInfo="projInfo"></projInfo>

        <gzh style="margin-top: 15px;"></gzh>
        <support style="margin-top: 20px;" :projInfo="projInfo"></support>
        <bottomFooter v-if="footVisible" :projInfo="projInfo"></bottomFooter>

        <div style="height: 60px;"></div>
    </div>
</template>

<script>
import top from './cpt/top/index.vue';
import support from './cpt/support/index.vue';
import gzh from './cpt/gongZhongHao/index.vue';
import projInfo from './cpt/projInfo/index.vue';
import bottomFooter from './cpt/bottomFooter/index.vue';
import api from './api.js';
import utils from '@/common/js/utils';
import saUtils from 'sensors-data-utils';
import { authChecker, shareCtrl, tracking } from '@/common/js/mmcModule';

var reqObj = utils.getRequestParams();

const pageKey = 'h5_jsb_onepoint_detail';
export default {
    data() {
        return {
            dataCom: false,
            footVisible:false,

            projInfo: {},
        };
    },
    computed: {
        typeName: function() {
            return this.projInfo.pet_type == 1 ? '猫' : '狗';
        },
    },
    methods: {
        toPaiHang() {
            location.href =
                '/fund/activity/onepoint/rankList?rc=button.p.20230919.onepointdetail.ranklist&group_type=' +
                this.projInfo.pet_type;
        },
    },
    components: {
        top,
        support,
        gzh,
        projInfo,
        bottomFooter,
    },
    created() {
        // authChecker.check({ notneedphone: 1 }, () => {

        // })
        api.getInfo(reqObj.id).then(res => {
            console.log(res);
            if (res.code == 0) {
                this.projInfo = res.data;
                this.dataCom = true;
                if (reqObj.ismock) {
                    this.projInfo.is_main = false;
                    this.projInfo.pet_type = 2;
                    this.projInfo.is_receive = false;
                    this.projInfo.activity_state = 2;
                    this.projInfo.is_helped = true;
                    this.projInfo.receive_num = 10;
                    this.projInfo.help_list = [
                        {
                            nick_name: 'lidong',
                            amount: 0.02,
                            avatar:
                                'https://jisuchou.oss-cn-hangzhou.aliyuncs.com/jsc/1a3c49d1-4f8a-490a-84f7-6a4c5d25564a',
                        },
                        {
                            nick_name: '李冬',
                            amount: 0.04,
                            avatar:
                                'https://jisuchou.oss-cn-hangzhou.aliyuncs.com/jsc/1a3c49d1-4f8a-490a-84f7-6a4c5d25564a',
                        },
                        {
                            nick_name: 'lidong',
                            amount: 0.01,
                            avatar:
                                'https://jisuchou.oss-cn-hangzhou.aliyuncs.com/jsc/1a3c49d1-4f8a-490a-84f7-6a4c5d25564a',
                        },
                    ];
                }
                shareCtrl.init({
                    title: '我正参加宠物筹5毛钱抢129元卫仕宠物口粮活动',
                    imgUrl: this.projInfo.pet_image + '@!thumb.png',
                    desc: '点击链接，帮我助力1分钱',
                    url: `https://m.jisuchou.net/fund/activity/onepoint/detail?id=${reqObj.id}`,
                    titleMoment: '我正参加宠物筹5毛钱抢129元卫仕宠物口粮活动',
                    shareRecord: {
                        scene: pageKey,
                    },
                });

                tracking.init();

                saUtils.init(pageKey);
                if (this.projInfo.is_main) {
                    saUtils.set('manager', 1);
                } else {
                    saUtils.set('manager', 0);
                }

                saUtils.pv();
            }

            window.document.addEventListener('scroll', () => {
                // alert(document.documentElement.scrollTop)
                var scrollTop =
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                // if()
                if (scrollTop > 370) {
                    //滚动条的高度，可以动态获取也可以写死
                    this.footVisible = true;
                    // alert(document.documentElement.scrollTop)
                    //这里写要执行的操作
                } else {
                    this.footVisible = false;
                }
            });
        });
    },
};
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    padding: 0 15px;
    overflow-x: hidden;

    .huizong {
        margin: 20px 0;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        .left {
            font-size: 16px;
            color: #000;
            span {
                color: #fe771b;
            }
        }
        .right {
            font-size: 16px;
            color: #000;
            text-decoration: underline;
        }
    }
}
</style>
