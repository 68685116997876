import { mmcAjax } from '@/common/js/mmcModule';

function perCheck() {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/penny/apply/check`,
        crossDomain: true,
    })
}

function getInfo(n) {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/penny/detail/${n}`,
        crossDomain: true,
    })
}

export default {
    perCheck,
    getInfo

}