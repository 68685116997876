<template>
    <div class="wrap">
        <template v-if="projInfo.activity_state == 1">
            <section class="one-btn">
                <div class="all" @click="toHome">帮助更多宠物</div>
            </section>
        </template>
        <template v-else-if="projInfo.activity_state == 2">
            <template v-if="projInfo.is_main">
                <section class="one-btn" v-if="projInfo.is_receive">
                    <div class="all" @click="toHome">帮助更多宠物</div>
                </section>
                <section class="one-btn" v-else>
                    <div class="all" @click="openShare('h5_jsb_now_share')">立即转发</div>
                </section>
            </template>
            <template v-else>
                <template v-if="projInfo.is_receive">
                    <div class="all" @click="toHome">帮助更多宠物</div>
                </template>
                <template v-else>
                    <section class="two-btn" v-if="!projInfo.is_helped">
                        <span class="left" @click="toHome">帮助更多宠物</span
                        ><span class="right" @click="toPay">一分钱助力</span>
                    </section>
                    <section v-else class="two-btn">
                        <span class="left" @click="toHome">帮助更多宠物</span
                        ><span class="right" @click="openShare('button_share')">帮忙转发</span>
                    </section>
                </template>
            </template>
        </template>
        <template v-else>
            <section class="one-btn">
                <div class="all" @click="toHome">帮助更多宠物</div>
            </section>
        </template>

        <div class="share-box" v-if="sharebox" @click="sharebox = false">
            <img src="./share.png" alt="" />
        </div>
    </div>
</template>

<script>
import saUtils from 'sensors-data-utils';
export default {
    data() {
        return {
            sharebox: false,
        };
    },
    props: ['projInfo'],
    methods: {
        openShare(s) {
            this.sharebox = true;
            saUtils.update({
                but_name:'bottomr'
            })
            saUtils.click(s)
        },
        toHome() {
            location.href = '/fund/home?rc=button.p.20230919.onepointdetail.chongwu';
        },
        toPay() {
            location.href = '/fund/project/payment/paytableonepoint?money=1&rc=button.p.20230925.onepointdetail.bottomr&id=' + this.projInfo.penny_pet_id;
        },
    },
};
</script>

<style lang="less" scoped>
.wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    .one-btn {
        display: flex;
        justify-content: space-between;
        align-content: center;
        
    }
    .all {
            width: 100%;
            padding: 13px;
            line-height: 1em;
            font-weight: 500;
            text-align: center;
            background: #fe771b;
            color: #fff;
            font-size: 18px;
        }
    .two-btn {
        display: flex;
        justify-content: space-between;
        align-content: center;

        span {
            display: inline-block;
            width: 50%;
            padding: 13px;
            line-height: 1em;
            font-weight: 500;
            text-align: center;
            font-size: 18px;
        }
        .left {
            background-color: #fff;
            color: #fe771b;
        }
        .right {
            background-color: #fe771b;
            color: #fff;
        }
    }
}
.share-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    img {
        width: 76%;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>
