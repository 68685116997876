<template>
    <div class="wrap">
        <img  src="./cwc-info.png" alt="">
        <p>感谢您的帮助<br>
添加客服查看捐助口粮发货信息</p>
        <img class="cwc-qrcode" src="./cwc-qrcode.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        font-size: 18px;
        text-align: center;
        color: #333;
        font-weight: 500;
        margin-top: 25px;
        margin-bottom: 18px;
    }
}
.cwc-qrcode{
    width: 160px;
    height: 160px;
}
</style>