<template>
    <div class="wrap">
        <div class="title">助力列表<span>({{ projInfo.help_list.length }})</span></div>
        <section class="item" v-for="(item,ind) in projInfo.help_list" :key="ind">
            <div class="left">
                <img :src="item.avatar" alt="" />
                <span>{{item.nick_name}}</span>
            </div>
            <div class="right">助力了<span>{{item.amount/100}}</span>元</div>
        </section>
    </div>
</template>

<script>
export default {
    props: ['projInfo'],
};
</script>

<style lang="less" scoped>
.wrap {
    background-color: #fff;
    margin: 0 -15px;
    text-align: left;
    padding: 20px;
    .title {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        span{
            color:#fe771b;
            margin-left: 5px;
        }
    }
    .item{
        margin: 15px 0 ;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
            display: flex;
            align-items: center;
            img{
                width: 36px;
                height: 36px;
                border-radius: 18px;
            }
            span{
                margin-left: 5px;
            }
        }
        .right{
            color: #333333;
            font-size: 14px;
            span{
                color:#fe771b;
                margin-left: 5px;
            }
        }
    }
}
</style>
