<template>
    <div
        class="wrap "
        :class="{ gou: isGou, mao: isMao, smallHeight: smallHeight }"
    >
        <span class="diao" @click="openGuiZe">活动规则</span>
        <guize :visible.sync="guiZeVisible"></guize>
        <div class="bottom">
            <template v-if="projInfo.activity_state == 1">
                <section class="one-btn" style="font-size: 16px;font-weight: 500;margin-bottom: 40px;">
                    活动暂未开始
                </section>
            </template>
            <template v-else-if="projInfo.activity_state == 2">
                <div  v-if="!projInfo.is_receive" style="display: flex;justify-content: center;">
                    <div class="num-tip">
                        <section>
                            还需助力：<span
                                >{{ projInfo.need_help_num }}人</span
                            >
                        </section>
                        <section>已助力：{{ projInfo.help_num }}人</section>
                    </div>
                </div>

                <template v-if="projInfo.is_main">
                    <section class="one-btn" v-if="projInfo.is_receive">
                        <img class="tip-img" src="./tip-success.png" alt="" />
                    </section>
                    <section class="one-btn" v-else>
                        <img
                            class="btn-img"
                            @click="openShare('h5_jsb_now_share')"
                            src="./btn-lijizhuanfa.png"
                            alt=""
                        />
                    </section>
                </template>
                <template v-else>
                    <template v-if="projInfo.is_receive">
                        <img class="tip-img" src="./tip-success.png" alt="" />
                    </template>
                    <template v-else>
                        <section class="one-btn" v-if="!projInfo.is_helped">
                            <img
                                class="btn-img"
                                @click="toPay"
                                src="./btn-zhuli.png"
                                alt=""
                            />
                        </section>
                        <section class="one-btn" v-else>
                            <img
                                class="btn-img"
                                @click="openShare('button_share')"
                                src="./btn-bangmang.png"
                                alt=""
                            />
                        </section>
                    </template>
                </template>
            </template>
            <template v-else>
                <section class="one-btn">
                    <template v-if="projInfo.is_receive">
                        <img class="tip-img" src="./tip-success.png" alt="" />
                    </template>
                    <template v-else>
                        <img class="tip-img" src="./tip-over.png" alt="" />
                    </template>
                </section>
            </template>

            <div class="share-box" v-if="sharebox" @click="sharebox = false">
                <img src="./share.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import guize from '../../../apply/guize.vue';
import guizhe from './guizhe.vue';
import saUtils from 'sensors-data-utils';
export default {
    components: { guize },
    data() {
        return {
            isGou: false,
            isMao: false,
            guiZeVisible: false,
            sharebox: false,
        };
    },
    props: ['projInfo'],
    methods: {
        openGuiZe() {
            this.guiZeVisible = true;
        },
        openShare(s) {
            this.sharebox = true;
            saUtils.update({
                but_name:'center'
            })
            saUtils.click(s)
            
        },
        toPay() {
            location.href =
                '/fund/project/payment/paytableonepoint?money=1&rc=button.p.20230925.onepointdetail.center&id=' +
                this.projInfo.penny_pet_id;
        },
    },
    computed: {
        smallHeight: function() {
            if (this.projInfo.activity_state == 3) {
                return true;
            }
            if (this.projInfo.is_receive) {
                return true;
            }
            return false;
        },
    },
    components: {
        guize,
    },

    mounted() {
        if (this.projInfo.pet_type == 1) {
            this.isMao = true;
        } else {
            this.isGou = true;
        }
    },
};
</script>

<style lang="less" scoped>
.wrap {
    height: 100vw;
    margin: 0 -20px;
    position: relative;

    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;

    &.smallHeight {
        height: calc(100vw - 50px);
        background-size: cover;
    }

    .diao {
        position: absolute;
        right: 5px;
        top: 14px;
        background-color: #40ae00;
        padding: 5px 10px 4px;
        font-size: 12px;
        border-radius: 11px 0 0 11px;
        color: #fff;
        line-height: 1em;
        
    }
    &.mao {
        background-image: url(./bg-mao.png);
    }
    &.gou {
        background-image: url(./bg-gou.png);
    }
    .bottom {
        position: absolute;
        bottom: 0px;
        left: 0;
        text-align: center;
        width: 100%;
        // .one-btn{
        //     display: flex;
        //     justify-content: center;
        // }

        .btn-img {
            width: 345px;
            height: 53px;
            display: inline-block;
        }
        .tip-img {
            width: 255px;
            height: 35px;
            display: inline-block;
        }
    }

    .share-box {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 999;
        img {
            width: 76%;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    .num-tip {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 1em;
        margin-bottom: 16px;
        width: 345px;
        ;
        span {
            color: #fe771b;
        }
    }
}
</style>
