<template>
    <div class="wrap">
        <div class="title">
            <img  src="./title-bg.png" alt="">
        </div>

        <p style="margin-top: 13px;">名称：{{ projInfo.pet_name }}</p>
        <div class="manager">
            <span>主人：</span>
            <section class="manager-info">
                <img :src="projInfo.avatar" alt="" />
                <span>{{ projInfo.nick_name }}</span>
            </section>
        </div>
        <div v-viewer class="pet-photo" :style="`background-image: url(${projInfo.pet_image});`">
            <img class="preview-img" style="opacity:0" :src="projInfo.pet_image+'?x-image-process=style/thumb.png'"   alt="" 
                    :data-preview="projInfo.pet_image+'?x-image-process=style/love.png'" />
            
        </div>
       
       
    </div>
</template>

<script>
import '@/common/js/directives/useViewer';
export default {
    props:['projInfo']
};
</script>
<style lang="less" scoped>
.wrap {
    border: 1px solid #333;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    color: #000;
    .title{
        text-align: center;
        img{
            display: inline-block;
            width: 80px;
            height: 25px;
        }
    }
    .manager{
        display: flex;
        align-items: center;
        margin-top: 8px;
        .manager-info{
            display: flex;
            align-content: center;
            justify-content: left;
            img{
                width: 20px;
                height: 20px;
                border-radius: 10px;
            }
            span{
                margin-left: 5px;
            }
        }

    }
    .pet-photo{
            margin-top: 15px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 5px;
            width: 82vw;
            height: 82vw;
            // width: 305px;
            // height: 305px;
        }
}
</style>
